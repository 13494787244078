import { createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: { title: "ページタイトル" },
    children: [
      {
        path: "",
        name: "Dashboard",
        meta: { title: "ダッシュボード" },
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/library/",
        name: "Library",
        meta: { title: "組織管理" },
        component: () => import("@/views/Library/Index.vue"),
      },
      {
        path: "/library/edit/:id",
        name: "LibraryEdit",
        meta: { title: "組織編集", role: ["admin"] },
        component: () => import("@/views/Library/Edit.vue"),
      },
      {
        path: "/library/users/add/",
        name: "LibraryUsersAdd",
        meta: { title: "ユーザー登録", role: ["admin"] },
        component: () => import("@/views/Library/Users/Add.vue"),
      },
      {
        path: "/library/users/detail/:id",
        name: "LibraryUsersDetail",
        meta: { title: "ユーザー詳細", role: ["admin"] },
        component: () => import("@/views/Library/Users/Detail.vue"),
      },
      {
        path: "/library/users/edit/:id",
        name: "LibraryUsersEdit",
        meta: { title: "ユーザー編集", role: ["admin"] },
        component: () => import("@/views/Library/Users/Edit.vue"),
      },
      {
        path: "/change_settings/",
        name: "ChangeSettings",
        meta: { title: "個人設定変更" },
        component: () => import("@/views/ChangeSettings.vue"),
      },
      {
        path: "/change_password/",
        name: "ChangePassword",
        meta: { title: "パスワード変更" },
        component: () => import("@/views/ChangePassword.vue"),
      },
      {
        path: "/surveys",
        name: "Surveys",
        meta: { title: "調査票一覧" },
        component: () => import("@/views/Survey/Index.vue"),
      },
      {
        path: "/surveys",
        name: "Surveys",
        meta: { title: "調査票一覧", isPublic: true },
        component: () => import("@/views/Surveys/Index.vue"),
      },
      {
        path: "/faqs",
        name: "Faqs",
        meta: { title: "FAQ一覧" },
        component: () => import("@/views/Faq/Index.vue"),
      },
      {
        path: "/surveys/answer/:surveyId/publication",
        name: "SurveysPublication",
        meta: { title: "共有可否入力", isPublic: true },
        component: () => import("@/views/Surveys/Publication.vue"),
      },
    ],
  },
  {
    path: "/survey/detail/:id",
    name: "SurveyDetail",
    meta: { title: "調査票詳細" },
    component: () => import("@/views/Survey/Detail.vue"),
  },
  {
    path: "/surveys/answer/:surveyId/:pageNo?",
    name: "SurveysAnswer",
    meta: { title: "回答入力", isPublic: true },
    component: () => import("@/views/Surveys/Answer.vue"),
  },
  {
    path: "/surveys/answer/confirm",
    name: "SurveysAnswerConfirm",
    meta: { title: "回答入力確認", isPublic: true },
    component: () => import("@/views/Surveys/AnswerConfirm.vue"),
  },
  {
    path: "/survey/detail_print/:id",
    name: "SurveyDetailPrint",
    meta: { title: "調査票詳細（印刷用）" },
    component: () => import("@/views/Survey/DetailPrint.vue"),
  },
  {
    path: "/login/",
    name: "Login",
    meta: { title: "ログイン" },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/forgot_password/",
    name: "ForgotPassword",
    meta: { title: "パスワードリセット依頼", isPublic: true },
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/reset_password/:token",
    name: "ResetPassword",
    meta: { title: "パスワードリセット", isPublic: true },
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/change_initial_password/",
    name: "ChangeInitialPassword",
    meta: { title: "初期パスワード変更", isPublic: true },
    component: () => import("@/views/ChangeInitialPassword.vue"),
  },
  {
    name: "denied",
    path: "/denied",
    component: () => import("@/views/403"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //ブラウザ遷移の位置対策
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0,
      };
    }
  },
});
import axios from "axios";
import { checkAuth, checkAuthByRole, checkEnabled } from "../mixins/auth.js";
router.beforeEach((to, from, next) => {
  //isPublicがtrueの場合には必ずアクセス可能
  //各ルートに meta: { isPublic: true } を追加します。
  if (to.matched.some((page) => page.meta.isPublic)) {
    next();
  } else {
    //async/awaitでそれぞれのログイン状況を取得する
    (async () => {
      let Auth = await checkAuth();
      //ログイン済
      if (Auth) {
        let is_next = true;
        //(種別)
        if (to.meta.role) {
          is_next = false;
          let AuthRole = await checkAuthByRole(to.meta.role);
          if (AuthRole) {
            is_next = true;
          }
        }

        let is_enabled = await checkEnabled();
        if (!is_enabled) {
          next({ path: "/change_initial_password" });
        }
         
        if(to.path.startsWith("/files")) {
          let fileName = to.path.split("/")[2];
          await axios
            .get(`/api`+ to.path, { responseType: "blob" })
            .then((response) => {
                let blob = new Blob([response.data], { type: "application/*" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            })
            .catch((error) => {
            }).finally(()=>{
              return false;
            });
            window.close();
            next(false); 
            return false;    
        }

        //権限あり
        if (is_next) {
          next();
        } else {
          if (to.path == "/") {
            next({ path: "/login" });
          } else {
            // 権限のない画面にアクセスしようとした：ひとまずloginに戻す
            next({ path: "/login" });
          }
        }
      }
      //ログインしていない
      else {
        if (to.path.indexOf("/auth") === 0 || to.path.indexOf("/login") === 0) {
          next();
        } else {
          next({ path: "/login" });
        }
      }
    })();
  }
});

export default router;
